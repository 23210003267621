<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-header">
          <span>{{ $route.meta.title }}</span>
          <div class="card-operation"></div>
        </div>
        <div class="page-card-boody page-wrap-960">
          <el-form :model="pageForm" :rules="pageRules" label-width="120px" ref="pageFormRef"
                   v-loading="page_form_loading">
            <el-form-item label="发电户号" prop="generatorNo">
              <el-input v-model="pageForm.generatorNo" placeholder="发电户号"></el-input>
            </el-form-item>
            <el-form-item label="开发商" prop="developersId">
              <el-select v-model="pageForm.developersId" filterable clearable placeholder="开发商">
                <el-option
                    v-for="item in developer_options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经销商" prop="distributorId">
              <el-select v-model="pageForm.distributorId" filterable clearable placeholder="经销商">
                <el-option
                    v-for="item in distributor_options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户姓名" prop="name">
              <el-input v-model="pageForm.name" placeholder="用户姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="idNo">
              <el-input v-model="pageForm.idNo" placeholder="身份证号"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="pageForm.phone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="bankNo">
              <el-input v-model="pageForm.bankNo" placeholder="银行卡号"></el-input>
            </el-form-item>
            <el-form-item label="合同类型" prop="type">
              <el-select v-model="pageForm.type" placeholder="合同类型">
                <el-option :label="label" :value="value" v-for="(label,value) in typeOptions" :key="label"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="省份" prop="provinceCode">
              <el-select v-model="pageForm.provinceCode" placeholder="省份" @change="provinceChange">
                <el-option v-for="(item, index) in province_options" :key="index" :label="item.province"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="组件块数" prop="size">
              <el-input v-model.number="pageForm.size" :min="0" placeholder="组件块数"></el-input>
            </el-form-item>
            <el-form-item label="还款日" prop="day">
              <el-select v-model="pageForm.day" placeholder="还款日">
                <el-option v-for="i in 31" :key="i" :label="i"
                           :value="i"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="签订日期" prop="contractTime">
              <el-date-picker
                  v-model="pageForm.contractTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="签订日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="并网日期" prop="signTime">
              <el-date-picker
                  v-model="pageForm.signTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="签订日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="pageForm.remarks" placeholder="备注"></el-input>
            </el-form-item>
            <el-form-item label="电站编号" prop="powerStationCode">
              <el-input v-model="pageForm.powerStationCode" placeholder="电站编号"></el-input>
            </el-form-item>
            <el-form-item label="屋顶类型" prop="roofType">
              <el-select v-model="pageForm.roofType" placeholder="屋顶类型">
                <el-option v-for="type in roof_type_options" :key="type.type" :label="type.name"
                           :value="type.type"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :disabled="!canQueryTransfer" @click="makeTransfer">生成划转信息</el-button>
            </el-form-item>
            <div v-if="transfer_data" style="padding: 0 120px 50px 120px;width: 100%;box-sizing: border-box;">
              <el-tabs v-model="transfer_year" class="demo-tabs">
                <el-tab-pane v-for="year in transfer_years" :key="year" :label="year" :name="year"></el-tab-pane>
              </el-tabs>
              <div style="padding: 15px;text-align: center">共{{
                  transfer_data[transfer_year].list.length
                }}期&nbsp;&nbsp;&nbsp;&nbsp;划转总计：{{ transfer_data[transfer_year].sum }}元
              </div>
              <el-table
                  border
                  :data="transfer_data[transfer_year].list"
                  style="width: 100%">
                <el-table-column
                    align="center"
                    prop="date"
                    label="还款日">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="money"
                    label="还款金额（元）">
                </el-table-column>
              </el-table>
            </div>
            <div class="dialog-footer">
              <el-button @click="cancel">取 消</el-button>
              <el-button @click="submit('pageFormRef')" type="primary">确 定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import {
  deUserInfo,
  deUserCreate,
  deUserUpdate, developerAll, distributorAll, roofTypeAll,
  seeTransferUpdate,
  seeTransferQuery,
} from "@/api/common"
import {PROVINCE_CODES} from "@/utils/config"

const typeMap = {
  "02" :"屋顶租赁",
  "03": "全款销售",
  "01": "个人贷款",
}

export default {
  name: "DeUserEdit",
  data() {
    return {
      id: null,
      user: null,
      user_page: null,
      page_form_loading: false,
      typeOptions: typeMap,
      pageForm: {
        generatorNo: '',
        developersId: '',
        distributorId: '',
        name: '',
        idNo: '',
        phone: '',
        bankNo: '',
        type: '',
        provinceCode: '',
        province: '',
        size: '',
        day: '',
        contractTime: '',
        signTime: '',
        remarks: '',
        powerStationCode: '',
        roofType: '',
      },
      pageRules: {
        generatorNo: [
          {required: true, message: "请输入发电户号", trigger: "blur"},
        ],
        developersId: [
          {required: true, message: "请选择开发商", trigger: "blur"},
        ],
        distributorId: [
          {required: true, message: "请选择经销商", trigger: "blur"},
        ],
        name: [
          {required: true, message: "请输入用户姓名", trigger: "blur"},
        ],
        idNo: [
          {required: true, message: "请输入身份证号", trigger: "blur"},
        ],
        phone: [
          {required: true, message: "请输入手机号码", trigger: "blur"},
        ],
        bankNo: [
          {required: true, message: "请输入银行卡号", trigger: "blur"},
        ],
        type: [
          {required: true, message: "请选择合同类型", trigger: "blur"},
        ],
        provinceCode: [
          {required: true, message: "请选择省", trigger: "blur"},
        ],
        size1: [
          {required: true, type: 'number', min: 0, message: "类型为数字且不能小于0", trigger: "blur"},
        ],
        day: [
          {required: true, message: "请选择还款日", trigger: "blur"},
        ],
        contractTime: [
          {required: true, message: "请选择签订日期", trigger: "blur"},
        ],
        signTime: [
          {required: true, message: "请选择并网日期", trigger: "blur"},
        ],
        roofType: [
          {required: true, message: "请选择屋顶类型", trigger: "blur"},
        ],
      },
      developer_options: [],
      distributor_options: [],
      province_options: PROVINCE_CODES,
      roof_type_options: [],
      transfer_data: null,
      transfer_years: [],
      transfer_year: '',
    }
  },
  components: {
    PageHeader,
  },
  computed: {
    canQueryTransfer() {
      return this.pageForm.day && this.pageForm.developersId && this.pageForm.signTime && this.pageForm.size && this.pageForm.distributorId && this.pageForm.roofType
    }
  },
  created() {
    this.id = this.$route.params.id || null
    if (this.id) {
      this.getDeUserInfo()
    }
  },
  mounted() {
    this.getDeveloperOptions()
    this.getDistributorOptions()
    this.getRoofTypeOptions()
  },
  methods: {
    getDeveloperOptions() {
      let that = this
      developerAll().then(res => {
        that.developer_options = res
      })
    },
    getDistributorOptions() {
      let that = this
      distributorAll().then(res => {
        that.distributor_options = res
      })
    },
    getRoofTypeOptions() {
      let that = this
      roofTypeAll().then(res => {
        that.roof_type_options = res
      })
    },
    getDeUserInfo() {
      let that = this
      deUserInfo(that.id).then(res => {
        this.pageForm = {
          id: res.id,
          generatorNo: res.generatorNo,
          developersId: res.developersId,
          distributorId: res.distributorId,
          name: res.name,
          idNo: res.idNo,
          phone: res.phone,
          bankNo: res.bankNo,
          type: res.type,
          provinceCode: parseInt(res.provinceCode),
          province: res.province,
          size: res.size,
          day: res.day,
          contractTime: res.contractTime,
          signTime: res.signTime,
          remarks: res.remarks,
          powerStationCode: res.powerStationCode,
          roofType: res.roofType,
          state: res.state,
          state1: res.state1,
          state2: res.state2,
          version: res.version,
        }
      })
    },
    provinceChange(val) {
      let province = this.province_options.find(item => {
        return item.id == val
      })
      if (province) {
        this.pageForm.province = province.province
      } else {
        this.pageForm.province = ''
      }
    },
    cancel() {
      this.$emit('closeView', '/admin/de-user')
    },
    submit(formName) {
      let that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.page_form_loading = true
          let func
          // eslint-disable-next-line no-prototype-builtins
          if (that.pageForm.hasOwnProperty('id')) {
            func = deUserUpdate
          } else {
            func = deUserCreate
          }
          func(that.pageForm).then(() => {
            that.page_form_loading = false
            that.$message.success('操作成功')
            setTimeout(() => {
              that.$emit('closeView', '/admin/de-user')
            }, 2000)
          }).catch(() => {
            that.page_form_loading = false
          })
        }
      })
    },
    makeTransfer() {
      let that = this
      if (!that.canQueryTransfer) return
      that.page_form_loading = true
      that.transfer_year = ''
      that.transfer_years = []
      that.transfer_data = null
      let query = {
        day: that.pageForm.day,
        developersId: that.pageForm.developersId,
        signTime: that.pageForm.signTime,
        size1: that.pageForm.size,
        distributorId: that.pageForm.distributorId,
        roofType: that.pageForm.roofType,
      }, func
      if (that.id) {
        func = seeTransferUpdate
        query.userId = this.id
      } else {
        func = seeTransferQuery
      }
      func(query).then(res => {
        that.page_form_loading = false
        let years = Object.keys(res)
        let sum_index = years.findIndex(item => {
          return item == 'sum'
        })
        if (sum_index >= 0) {
          years.splice(sum_index, 1)
        }
        if (years.length > 0) {
          let transfer_data = res
          for (let year of years) {
            let list = []
            for (let date in transfer_data[year].data) {
              list.push({
                date: date,
                money: transfer_data[year].data[date]
              })
            }
            transfer_data[year].list = list
          }
          this.transfer_data = transfer_data
          this.transfer_years = years
          this.transfer_year = years[0]
          console.log(this.transfer_data)
        } else {
          that.$message.error("未查询到划转信息")
        }
      }).catch(() => {
        that.page_form_loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
